import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    description: {
        id: 'consultations.CompletedConsultation.description',
        defaultMessage: 'description',
    },
    header: {
        id: 'consultations.CompletedConsultation.header',
        defaultMessage: 'header',
    },
});

export class CompletedConsultation extends React.Component {
    componentDidMount() {
        if (this.props.walkIn) {
            window.location.assign('/consultations/new/');
        }
    }

    render() {
        const intl = this.props.intl;
        return (
            <section className="view-status">
                <figure>
                    <Icon name="IconCompletedConsultation" />
                </figure>
                <header>
                    <h1 data-test-id="consultation-status-title">
                        {intl.formatMessage(messages.header)}
                    </h1>
                    <p data-test-id="consultation-status-message">
                        {intl.formatMessage(messages.description)}
                    </p>
                </header>
            </section>
        );
    }
}

function mapStateToProps(store) {
    return {
        walkIn: store.accounts.user.isWalkInUser,
    };
}

export default injectIntl(connect(mapStateToProps)(CompletedConsultation));
