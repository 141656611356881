import BeginConsultationStep from 'consultations/components/BeginConsultationStep.js';
import CharacterStep from 'consultations/components/CharacterStep.js';
import CompletedConsultation from 'consultations/components/CompletedConsultation.js';
import Consultation from 'consultations/components/Consultation.js';
import ConsultationDeletedError from 'consultations/components/ConsultationDeletedError.js';
import ConsultationPermissionError from 'accounts/components/ConsultationPermissionError.js';
import ConsultationTokenCheckError from 'consultations/components/ConsultationTokenCheckError.js';
import DemographicsAgeStep from 'consultations/components/DemographicsAgeStep.js';
import DemographicsDateOfBirthStep from 'consultations/components/DemographicsDateOfBirthStep.js';
import DemographicsMaritalStatusStep from 'consultations/components/DemographicsMaritalStatusStep.js';
import DemographicsEmploymentStatusStep from 'consultations/components/DemographicsEmploymentStatusStep.js';
import DemographicsCareEnvironmentStep from 'consultations/components/DemographicsCareEnvironmentStep.js';
import DemographicsSmokingStatusStep from 'consultations/components/DemographicsSmokingStatusStep.js';
import DemographicsSmokingCountStep from 'consultations/components/DemographicsSmokingCountStep.js';
import DemographicsSmokingDurationStep from 'consultations/components/DemographicsSmokingDurationStep.js';
import DemographicsSmokingQuitDurationStep from 'consultations/components/DemographicsSmokingQuitDurationStep.js';
import DemographicsAlcoholFrequencyStep from 'consultations/components/DemographicsAlcoholFrequencyStep.js';
import DemographicsAlcoholQuantityStep from 'consultations/components/DemographicsAlcoholQuantityStep.js';
import DemographicsRecreationalDrugStatusStep from 'consultations/components/DemographicsRecreationalDrugStatusStep.js';
import DemographicsRecreationalDrugChoicesStep from 'consultations/components/DemographicsRecreationalDrugChoicesStep.js';
import DemographicsRecreationalDrugIndividualFrequencyStep from 'consultations/components/DemographicsRecreationalDrugIndividualFrequencyStep.js'; // eslint-disable-line max-len
import DemographicsSexStep from 'consultations/components/DemographicsSexStep.js';
import DurationStep from 'consultations/components/DurationStep.js';
import ExacerbatingFactorsStep from 'consultations/components/ExacerbatingFactorsStep.js';
import FirstOccurrenceStep from 'consultations/components/FirstOccurrenceStep.js';
import FamilyConditionRelationshipStep from 'consultations/components/FamilyConditionRelationshipStep.js';
import FamilyConditionsStep from 'consultations/components/FamilyConditionsStep.js';
import FeedbackQuestionCheckpointStep from 'consultations/components/FeedbackQuestionCheckpointStep.js';
import FreeTextProblemConfirmStep from 'consultations/components/FreeTextProblemConfirmStep.js';
import GlobalQuestionCheckpointStep from 'consultations/components/GlobalQuestionCheckpointStep.js';
import GlobalQuestionStep from 'consultations/components/GlobalQuestionStep.js';
import ImpactsOnFunctionStep from 'consultations/components/ImpactsOnFunctionStep.js';
import MedicationComplianceStep from 'consultations/components/MedicationComplianceStep.js';
import MedicationForPrescriptionStep from 'consultations/components/MedicationForPrescriptionStep.js';
import MedicationForProblemsStep from 'consultations/components/MedicationForProblemsStep.js';
import MedicationStep from 'consultations/components/MedicationStep.js';
import DemographicsOccupationStep from 'consultations/components/DemographicsOccupationStep.js';
import PastConditionHasBeenDiagnosedStep from 'consultations/components/PastConditionHasBeenDiagnosedStep.js';
import PastConditionStep from 'consultations/components/PastConditionStep.js';
import PastConditionWhenDiagnosedStep from 'consultations/components/PastConditionWhenDiagnosedStep.js';
import PatientNameStep from 'consultations/components/PatientNameStep.js';
import PatientSelectionStep from 'consultations/components/PatientSelectionStep.js';
import PatternStep from 'consultations/components/PatternStep.js';
import ProblemPastConditionsCheckpointStep from 'consultations/components/ProblemPastConditionsCheckpointStep.js';
import ProblemPastConditionsStep from 'consultations/components/ProblemPastConditionsStep.js';
import ProblemSearchStep from 'consultations/components/ProblemSearchStep.js';
import ProblemQuestionStep from 'consultations/components/ProblemQuestionStep.js';
import ProblemSeenBeforeStep from 'consultations/components/ProblemSeenBeforeStep.js';
import TimeOfDayStep from 'consultations/components/TimeOfDayStep.js';
import EpisodeLengthStep from 'consultations/components/EpisodeLengthStep.js';
import FrequencyStep from 'consultations/components/FrequencyStep.js';
import RecentChangeStep from 'consultations/components/RecentChangeStep.js';
import RecentChangeTimeFrameStep from 'consultations/components/RecentChangeTimeFrameStep.js';
import RelatedSymptomsScreenStep from 'consultations/components/RelatedSymptomsScreenStep.js';
import RelatedSymptomsStep from 'consultations/components/RelatedSymptomsStep.js';
import RelatedSymptomsStepV2 from 'consultations/components/RelatedSymptomsStepV2.js';
import RelievingFactorsStep from 'consultations/components/RelievingFactorsStep.js';
import RepeatPrescriptionStep from 'consultations/components/RepeatPrescriptionStep.js';
import RepeatStep from 'consultations/components/RepeatStep.js';
import SeverityAtWorstStep from 'consultations/components/SeverityAtWorstStep.js';
import SeverityStep from 'consultations/components/SeverityStep.js';
import SeverityQuestionStep from 'consultations/components/SeverityQuestionStep.js';
import SubmitConsultationStep from 'consultations/components/SubmitConsultationStep';
import TriggerEventsStep from 'consultations/components/TriggerEventsStep.js';
import WalkInPatientFlowStep from 'consultations/components/WalkInPatientFlowStep.js';
import BodyPartLateralisationStep from 'consultations/components/BodyPartLateralisationStep.js';
import FeedbackQuestionStep from 'consultations/components/FeedbackQuestionStep.js';
import ReviewOfSystemsCheckpointStep from 'consultations/components/ReviewOfSystemsCheckpointStep.js';
import ReviewOfSystemsStep from 'consultations/components/ReviewOfSystemsStep.js';

export const componentsList = {
    BeginConsultationStep,
    BodyPartLateralisationStep,
    CharacterStep,
    CompletedConsultation,
    Consultation,
    ConsultationDeletedError,
    ConsultationPermissionError,
    ConsultationTokenCheckError,
    DemographicsAgeStep,
    DemographicsAlcoholFrequencyStep,
    DemographicsAlcoholQuantityStep,
    DemographicsDateOfBirthStep,
    DemographicsEmploymentStatusStep,
    DemographicsCareEnvironmentStep,
    DemographicsMaritalStatusStep,
    DemographicsOccupationStep,
    DemographicsRecreationalDrugChoicesStep,
    DemographicsRecreationalDrugIndividualFrequencyStep,
    DemographicsRecreationalDrugStatusStep,
    DemographicsSexStep,
    DemographicsSmokingCountStep,
    DemographicsSmokingDurationStep,
    DemographicsSmokingQuitDurationStep,
    DemographicsSmokingStatusStep,
    DurationStep,
    ExacerbatingFactorsStep,
    EpisodeLengthStep,
    FamilyConditionRelationshipStep,
    FamilyConditionsStep,
    FeedbackQuestionCheckpointStep,
    FeedbackQuestionStep,
    FirstOccurrenceStep,
    FreeTextProblemConfirmStep,
    FrequencyStep,
    GlobalQuestionCheckpointStep,
    GlobalQuestionStep,
    ImpactsOnFunctionStep,
    MedicationComplianceStep,
    MedicationForPrescriptionStep,
    MedicationForProblemsStep,
    MedicationStep,
    PastConditionHasBeenDiagnosedStep,
    PastConditionStep,
    PastConditionWhenDiagnosedStep,
    PatientNameStep,
    PatientSelectionStep,
    PatternStep,
    ProblemPastConditionsCheckpointStep,
    ProblemPastConditionsStep,
    ProblemSearchStep,
    ProblemQuestionStep,
    ProblemSeenBeforeStep,
    RecentChangeStep,
    RecentChangeTimeFrameStep,
    RelatedSymptomsScreenStep,
    RelatedSymptomsStep,
    RelatedSymptomsStepV2,
    RelievingFactorsStep,
    RepeatPrescriptionStep,
    RepeatStep,
    ReviewOfSystemsCheckpointStep,
    ReviewOfSystemsStep,
    SeverityAtWorstStep,
    SeverityStep,
    SeverityQuestionStep,
    SubmitConsultationStep,
    TimeOfDayStep,
    TriggerEventsStep,
    WalkInPatientFlowStep,
};
