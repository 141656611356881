import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {setLanguage} from 'accounts/actions/accountsActions.js';
import {CONSULTATIONS_CONSULTATIONS_API} from 'apiEndpoints.js';
import {
    getSupportedLanguages,
    showModal,
} from 'better_consult/actions/appActions.js';
import autobind from 'common/decorators/autobind.js';
import {createStep, solutions} from 'consultations/helpers.js';
import InputNotice from 'core/components/InputNotice.js';
import SelectInput from 'core/components/SelectInput.js';
import http from 'http.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import Icon from 'utils/components/Icon.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    buttonText: {
        id: 'consultations.BeginConsultationStep.buttonText',
        defaultMessage: 'buttonText',
    },
    confidential: {
        id: 'consultations.BeginConsultationStep.confidential',
        defaultMessage: 'confidential',
    },
    detailed: {
        id: 'consultations.BeginConsultationStep.detailed',
        defaultMessage: 'detailed',
    },
    duration: {
        id: 'consultations.BeginConsultationStep.duration',
        defaultMessage: 'duration',
    },
    getStarted: {
        id: 'consultations.BeginConsultationStep.getStarted',
        defaultMessage: 'getStarted',
    },
    language: {
        id: 'consultations.BeginConsultationStep.language',
        defaultMessage: 'language',
    },
    termsAndConditions: {
        id: 'consultations.BeginConsultationStep.termsAndConditions',
        defaultMessage: 'termsAndConditions',
    },
    termsError: {
        id: 'consultations.BeginConsultationStep.termsError',
        defaultMessage: 'termsError',
    },
});

/*
    Clinical:
    This step is always included.
    No real clinical application, just a step to explain BetterConsult to the
    user.

    Technical:
    Users must agree to a checkbox which explains that the doctor will not
    read this information until the time of the appointment.

    Dependencies:
    N/A

    Future:
    N/A
*/

export class BeginConsultationQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        enableLanguageSelect: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        reloadAction: PropTypes.func.isRequired,
        shouldReload: PropTypes.bool,
        showModal: PropTypes.func.isRequired,
        submitAction: PropTypes.func.isRequired,
        walkIn: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            agree: false,
            showTermsError: false,
            supportedLanguages: [],
        };
    }

    async componentDidMount() {
        if (this.props.enableLanguageSelect) {
            const supportedLanguages = await getSupportedLanguages();
            this.setState({supportedLanguages});
        }
    }

    @autobind
    showModal(event) {
        event.preventDefault();
        this.props.showModal('TERMS_MODAL');
    }

    @autobind
    handleAgree(event) {
        this.setState({
            agree: event.currentTarget.checked,
            showTermsError: false,
        });
    }

    @autobind
    async submitAction() {
        if (this.state.agree) {
            this.props.submitAction();
        } else {
            this.setState({showTermsError: true});
        }
    }

    @autobind
    setSelectedLanguage(value) {
        this.props.setLanguage(value);
    }

    renderZoneTermsAndConditions() {
        if (this.props.walkIn) {
            return (
                <FormattedMessage
                    // eslint-disable-next-line max-len
                    defaultMessage="consultations.BeginConsultationStep.accept"
                    id="consultations.BeginConsultationStep.accept"
                    values={{
                        terms: (
                            <a href="#terms" onClick={this.showModal}>
                                {this.props.intl.formatMessage(
                                    messages.termsAndConditions,
                                )}
                            </a>
                        ),
                    }}
                />
            );
        }
        return (
            <FormattedMessage
                // eslint-disable-next-line max-len
                defaultMessage="consultations.BeginConsultationStep.acceptTerms"
                id="consultations.BeginConsultationStep.acceptTerms"
            />
        );
    }

    renderConsentToggle(intl) {
        return (
            <label className="toggle" data-test-id="prompt-checkbox">
                {this.state.showTermsError && (
                    <InputNotice
                        errorId="terms-error"
                        message={intl.formatMessage(messages.termsError)}
                    />
                )}
                <input
                    aria-describedby="terms-error"
                    aria-invalid={this.state.showTermsError}
                    onChange={this.handleAgree}
                    type="checkbox"
                />
                <Icon name="IconCheckbox" />
                {this.renderZoneTermsAndConditions()}
            </label>
        );
    }

    render() {
        const intl = this.props.intl;
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.submitAction}
                reloadAction={this.props.reloadAction}
            >
                <div className="text-block" data-test-id="prompt-text-block">
                    <Icon name="IconDoctor" />
                    <p>{intl.formatMessage(messages.getStarted)}</p>
                    <p>
                        <FormattedMessage
                            // eslint-disable-next-line max-len
                            defaultMessage="consultations.BeginConsultationStep.questionnaireDuration"
                            // eslint-disable-next-line max-len
                            id="consultations.BeginConsultationStep.questionnaireDuration"
                            values={{
                                duration: (
                                    <strong>
                                        {intl.formatMessage(messages.duration)}
                                    </strong>
                                ),
                                detailed: (
                                    <strong>
                                        {intl.formatMessage(messages.detailed)}
                                    </strong>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            // eslint-disable-next-line max-len
                            defaultMessage="consultations.BeginConsultationStep.confidentialConsultation"
                            // eslint-disable-next-line max-len
                            id="consultations.BeginConsultationStep.confidentialConsultation"
                            values={{
                                confidential: (
                                    <strong>
                                        {intl.formatMessage(
                                            messages.confidential,
                                        )}
                                    </strong>
                                ),
                            }}
                        />
                    </p>
                </div>
                {this.props.enableLanguageSelect &&
                    this.state.supportedLanguages.length > 1 && (
                        <form>
                            <SelectInput
                                ariaLabel={intl.formatMessage(
                                    messages.language,
                                )}
                                handleChange={this.setSelectedLanguage}
                                iconName="IconLanguage"
                                initialValue={this.props.language}
                                inputName="language"
                                options={this.state.supportedLanguages.map(
                                    (l) => ({
                                        text: l.name,
                                        id: l.code,
                                    }),
                                )}
                            />
                        </form>
                    )}
                {this.renderConsentToggle(intl)}
                <AnswerSubmitButton
                    buttonText={intl.formatMessage(messages.buttonText)}
                    dataTestId="continue-button"
                    submitAnswers={this.submitAction}
                />
            </Question>
        );
    }
}

export default createStep({
    question: BeginConsultationQuestion,
    mapActions: (dispatch) => ({
        showModal: (modalType) => dispatch(showModal(modalType)),
        setLanguage: (language) => dispatch(setLanguage(language)),
    }),
    mapState: (store) => ({
        walkIn:
            store.data.consultations.consultation.solution ===
            solutions.WALK_IN,
        language: store.accounts.user.language,
    }),
    reloadAction: reloadBeginConsultationStep,
    submitAction: submitBeginConsultationStep,
});

export function reloadBeginConsultationStep() {
    return function (dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'BeginConsultationStep',
        );
        if (previousStep) {
            return {};
        }
    };
}

export function submitBeginConsultationStep(data, reload) {
    return async function (dispatch, getState) {
        if (!reload) {
            const store = getState();
            const id = store.data.consultations.consultation.id;
            await http.post({
                url: `${CONSULTATIONS_CONSULTATIONS_API}${id}/start/`,
            });
        }
    };
}
