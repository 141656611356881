import React from 'react';
import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import ChoiceQuestion from 'questions/components/ChoiceQuestion.js';
import {types} from 'questions/helpers.js';

const messages = defineMessages({
    questionText: {
        id: 'consultations.CharacterStep.questionText',
        defaultMessage: 'questionText',
    },
});

/*
    Clinical:
    The character of a symptom is the way it feels or presents, not how severe
    it is (e.g. 'spotty' rash or 'tingling' sore throat)

    Technical:
    Symptoms can have multiple CharacterGroups. When a symptom has one, the
    title of this question is defined in messages.json. When a symptom has
    multiple CharacterGroups, we use the layman translation on the Term linked
    to the CharacterGroup.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: ChoiceQuestion, // checkbox and radio only, not grouping
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
        title: props.multiple
            ? props.characterGroupText
            : props.intl.formatMessage(messages.questionText),
    }),
    reloadAction: reloadCharacterStep,
    submitAction: submitCharacterStep,
    undoAction: undoCharacterStep,
});

export function reloadCharacterStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const characterSteps = store.form.steps.filter(
            (step) => step.component.type === 'CharacterStep',
        );
        const currentStepIndex = characterSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'CharacterStep',
        );
        const previousStep = previousSteps[currentStepIndex];

        if (!previousStep) {
            return;
        }
        const currentStep = characterSteps[currentStepIndex];
        const {answers, problem, questionType} = currentStep.component.props;
        const characters =
            store.data.consultations.consultationSymptoms[
                problem.consultationSymptomId
            ].character;

        if (!characters) {
            return;
        }
        const selectedAnswers = answers.filter((answer) =>
            characters.some((character) => answer.id === character.id),
        );
        if (questionType === types.RADIO) {
            return {
                answer: selectedAnswers[0] || {id: null},
            };
        } else {
            return {
                answers: selectedAnswers,
            };
        }
    };
}

export function submitCharacterStep({props, answer, answers}, reload) {
    return async function(dispatch) {
        const {problem} = props;
        let toAdd = [];
        if (answer && answer.id) {
            toAdd = [answer.id];
        } else if (answers) {
            toAdd = answers.map((answer) => answer.id);
        }
        if (!reload) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'character',
                    toAdd,
                }),
            );
        }
    };
}

export function undoCharacterStep({props, answer, answers}) {
    return async function(dispatch) {
        const {problem} = props;
        let toRemove = [];
        if (answer) {
            toRemove = [answer.id];
        } else if (answers) {
            toRemove = answers.map((answer) => answer.id);
        }
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'character',
                toRemove,
            }),
        );
    };
}
