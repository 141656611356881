import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';

import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    header: {
        id: 'consultations.ConsultationTokenCheckError.header',
        defaultMessage: 'header',
    },
});

export class ConsultationTokenCheckError extends React.Component {
    render() {
        const intl = this.props.intl;
        return (
            <section className="view-status">
                <figure>
                    <Icon name="IconWarn" />
                </figure>
                <header>
                    <h1 data-test-id="consultation-status-title">
                        {intl.formatMessage(messages.header)}
                    </h1>
                    <p data-test-id="consultation-status-message">
                        <FormattedMessage
                            defaultMessage="description"
                            id="consultations.ConsultationTokenCheckError.description"
                            values={{
                                contactEmail: (
                                    <a href="mailto:support@betterconsult.com">
                                        {'support@betterconsult.com'}
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </header>
            </section>
        );
    }
}

export default injectIntl(ConsultationTokenCheckError);
