import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';

import {pushHistory} from 'better_consult/components/History.js';
import autobind from 'common/decorators/autobind.js';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    buttonText: {
        id: 'accounts.ConsultationPermissionError.buttonText',
        defaultMessage: 'buttonText',
    },
    description: {
        id: 'accounts.ConsultationPermissionError.description',
        defaultMessage: 'description',
    },
    header: {
        id: 'accounts.ConsultationPermissionError.header',
        defaultMessage: 'header',
    },
});

export class ConsultationPermissionError extends React.Component {
    @autobind
    async logoutAndRedirectToLogin(event) {
        event.preventDefault();
        await this.props.logoutUser();
        pushHistory('/login/', {
            preservedQueryKeys: ['token'],
        });
    }

    render() {
        const intl = this.props.intl;
        return (
            <section className="view-status">
                <figure>
                    <Icon name="IconPermissionDenied" />
                </figure>
                <header>
                    <h1 data-test-id="consultation-status-title">
                        {intl.formatMessage(messages.header)}
                    </h1>
                    <p data-test-id="consultation-status-message">
                        {intl.formatMessage(messages.description)}
                    </p>
                </header>
                <Link
                    className="cta"
                    data-test-id="logout-and-redirect-button"
                    onClick={this.logoutAndRedirectToLogin}
                    to={'/login/'}
                >
                    {intl.formatMessage(messages.buttonText)}
                </Link>
            </section>
        );
    }
}

export default injectIntl(ConsultationPermissionError);
