import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {
    bodyPartLateralisation,
    createStep,
    findPreviousProblemStep,
} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

export const messages = defineMessages({
    questionTitle: {
        id: 'consultations.BodyPartLateralisationStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    leftAnswer: {
        id: 'consultations.BodyPartLateralisationStep.leftAnswer',
        defaultMessage: 'leftAnswer',
    },
    rightAnswer: {
        id: 'consultations.BodyPartLateralisationStep.rightAnswer',
        defaultMessage: 'rightAnswer',
    },
    midAnswer: {
        id: 'consultations.BodyPartLateralisationStep.midAnswer',
        defaultMessage: 'midAnswer',
    },
});

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => {
        const title = props.intl.formatMessage(messages.questionTitle);
        const subTitle = props.problem.displayText;
        const answers = [
            {
                id: bodyPartLateralisation.left,
                text: props.intl.formatMessage(messages.leftAnswer),
            },
            {
                id: bodyPartLateralisation.right,
                text: props.intl.formatMessage(messages.rightAnswer),
            },
        ];
        if (props.hasMid) {
            answers.push({
                id: bodyPartLateralisation.mid,
                text: props.intl.formatMessage(messages.midAnswer),
            });
        }
        return {
            answers,
            subTitle,
            title,
            required: true,
        };
    },
    reloadAction: reloadBodyPartLateralisationStep,
    submitAction: submitBodyPartLateralisationStep,
    undoAction: undoBodyPartLateralisationStep,
});

export function reloadBodyPartLateralisationStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'BodyPartLateralisationStep',
            stepId,
        });
        if (!previousStep) {
            return;
        }
        const {consultationSymptomId} = previousStep.component.props.problem;
        const {
            bodyPartLateralisations,
        } = store.data.consultations.consultationSymptoms[
            consultationSymptomId
        ];

        if (!bodyPartLateralisations) {
            return;
        }
        return {
            answers: bodyPartLateralisations.map((id) => ({id})),
        };
    };
}

export function submitBodyPartLateralisationStep({answers, props}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {consultationSymptomId} = props.problem;
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'bodyPartLateralisations',
                    toReplace: answers.map((item) => item.id),
                }),
            );
        }
    };
}

export function undoBodyPartLateralisationStep({props}) {
    return async function(dispatch) {
        const {consultationSymptomId} = props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'bodyPartLateralisations',
                toReplace: [],
            }),
        );
    };
}
