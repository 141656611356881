import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    deletedInPms: {
        id: 'consultations.ConsultationDeletedError.deletedInPms',
        defaultMessage: 'deletedInPms',
    },
    deletedInPmsHeader: {
        id: 'consultations.ConsultationDeletedError.deletedInPmsHeader',
        defaultMessage: 'deletedInPmsHeader',
    },
});

export class ConsultationDeletedError extends React.Component {
    render() {
        const intl = this.props.intl;
        return (
            <section className="view-status">
                <figure>
                    <Icon name="IconConsultationDeleted" />
                </figure>
                <header>
                    <h1 data-test-id="consultation-status-title">
                        {intl.formatMessage(messages.deletedInPmsHeader)}
                    </h1>
                    <p data-test-id="consultation-status-message">
                        {intl.formatMessage(messages.deletedInPms)}
                    </p>
                </header>
            </section>
        );
    }
}

export default injectIntl(ConsultationDeletedError);
