import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {logoutUser} from 'accounts/actions/accountsActions.js';
import {
    hideModal,
    showNotification,
} from 'better_consult/actions/appActions.js';
import getComponent from 'componentsList.js';
import {checkConsultationToken} from 'consultations/actions/consultationActions.js';
import {setStepToUndo} from 'consultations/actions/formActions.js';
import InvalidToken from 'consultations/components/InvalidToken.js';
import MenuBar from 'core/components/MenuBar.js';
import query from 'query.js';

const messages = defineMessages({
    exitPagePrompt: {
        id: 'accounts.Login.exitPagePrompt',
        defaultMessage: 'exitPagePrompt',
    },
    newProblemNotice: {
        id: 'consultations.Consultation.newProblemNotice',
        defaultMessage: 'newProblemNotice',
    },
});

export class Consultation extends React.Component {
    constructor(props) {
        super(props);
        this.progressAddends = [0];
        this.progressIncrements = [0];
        this.state = {
            progressValue: 0,
        };
    }

    async componentDidMount() {
        const queryData = query.parse();
        if (!this.props.token) {
            await this.props.checkConsultationToken(queryData.token);
        }

        if (this.props.tokenChecked) {
            this.initialiseUndoOnHistoryBack();
        }
    }

    componentDidUpdate(prevProps) {
        const {activeProblem, undoStepIndex} = this.props;
        if (activeProblem !== prevProps.activeProblem && activeProblem) {
            const isUndo = undoStepIndex < prevProps.undoStepIndex;
            this.handleProblemNotification(isUndo);
        }
    }

    @autobind
    undoStep() {
        const {isStepLoading, undoStepIndex} = this.props;
        if (!isStepLoading) {
            const stepId = this.props.steps[undoStepIndex].id;
            this.props.setStepToUndo(stepId);
        }
    }

    pushActiveState() {
        // Wrap the history.pushState with setTimeout to work around the hanging
        // problem when using swipe back gesture in desktop Safari, iOS Safari
        // and iOS Chrome.
        setTimeout(() => history.pushState({active: true}, null), 0);
    }

    initialiseUndoOnHistoryBack() {
        // Handle undo on history back. We maintain two history states, one
        // where the state value is null and the other where active is set on
        // the state. Whenever a popstate event occurs we want to undo a
        // question if one is available, otherwise go back again.

        // Set scroll restoration to be manual instead of auto to prevent the
        // history back calls from causing the scroll to flicker.
        // Does not work currently in Edge.
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }
        let ignoreBack = false;
        window.onpopstate = () => {
            const {isModalOpen, undoStepIndex} = this.props;
            if (ignoreBack) {
                // ensure that we end up in the history state where
                // active is set
                this.pushActiveState();
            } else {
                if (isModalOpen) {
                    this.props.hideModal();
                    this.pushActiveState();
                } else if (undoStepIndex >= 0) {
                    this.pushActiveState();
                    this.undoStep();
                } else {
                    // need to pushState then go back two history states in
                    // order to prevent a forward state being present if there
                    // is no back state prior to this (affects mobile Safari)
                    this.pushActiveState();
                    history.go(-2);
                }
            }
            ignoreBack = false;
        };

        if (history.state && history.state.active) {
            // go back to the null state so that we can push state again
            ignoreBack = true;
            history.back();
        } else {
            this.pushActiveState();
        }
    }

    handleProblemNotification(isUndo) {
        const {activeProblem, steps} = this.props;

        // do not show notification if displayText is undefined
        if (!activeProblem.displayText) {
            return;
        }

        const isSecondaryProblem =
            steps.reduce((acc, step) => {
                const {problem} = step.component.props;
                if (problem && !acc.includes(problem.displayText)) {
                    acc.push(problem.displayText);
                }
                return acc;
            }, []).length > 1;

        if (isUndo || isSecondaryProblem) {
            const notification = {
                isTimedNotice: true,
                title: this.props.intl.formatMessage(
                    messages.newProblemNotice,
                    {
                        precedingText: isUndo ? 'backward' : 'forward',
                        problem: `<b>${activeProblem.displayText}</b>`,
                    },
                ),
                type: 'warning',
            };
            return this.props.showNotification(notification);
        }
    }

    formatValue(value, decimalPlace = 1) {
        return parseFloat(value.toFixed(decimalPlace));
    }

    isRenderedStep(step) {
        return (
            step.component.completed &&
            !step.component.type.endsWith('ScreenStep') &&
            !step.component.type.endsWith('CheckpointStep')
        );
    }

    renderSteps() {
        // need to find the current active step index as the array steps gets
        // rendered in reverse below
        let steps = this.props.steps;
        const currentIndex =
            steps.length -
            1 -
            steps.findIndex(
                (step) =>
                    (step.component.completed && step.loading) ||
                    !step.component.completed,
            );
        steps = steps.slice(0).reverse();
        // need to find the previously active step that was rendered
        // since some steps don't get rendered e.g. checkpoint steps
        let previousIndex = steps.findIndex(this.isRenderedStep);
        previousIndex = previousIndex === currentIndex ? -1 : previousIndex;
        return steps.map((step, index) => {
            const Component = getComponent(step.component.type);
            return (
                <Component
                    active={index === currentIndex}
                    completed={step.component.completed}
                    id={step.id}
                    isRenderedStep={this.isRenderedStep(step)}
                    key={step.id}
                    loading={step.loading}
                    previouslyActive={index === previousIndex}
                    shouldReload={!step.reloaded && this.props.reloading}
                    undo={step.undo}
                    undoable={step.undoable}
                    {...step.component.props}
                />
            );
        });
    }

    checkConsultationError() {
        switch (this.props.consultationError) {
            case 'permissionDenied':
                return 'ConsultationPermissionError';
            case 'completed':
                return 'CompletedConsultation';
            case 'deleted':
                return 'ConsultationDeletedError';
            case 'apiUnavailable':
                return 'ConsultationTokenCheckError';
            default:
                return null;
        }
    }

    render() {
        const consultationError = this.checkConsultationError();
        if (consultationError) {
            const ErrorComponent = getComponent(consultationError);
            return (
                <>
                    <MenuBar menuType="consultation" />
                    <ErrorComponent logoutUser={this.props.logoutUser} />
                </>
            );
        }
        if (!this.props.tokenChecked) {
            return null;
        } else if (this.props.token) {
            // Main Flow
            if (this.props.steps.length) {
                return (
                    <>
                        {this.renderSteps()}
                        <MenuBar
                            additionalClass="menu-consultation"
                            feedbackButtonType="modal"
                            handleBack={
                                this.props.undoStepIndex >= 0
                                    ? this.undoStep
                                    : null
                            }
                            menuHeader={this.props.consultationPracticeName}
                            menuType="consultation"
                            progressValue={this.props.progress.value}
                        />
                    </>
                );
            }
            return null;
        } else {
            const selectProps = {
                consultationError: this.props.consultationError,
                tokenChecked: this.props.tokenChecked,
                isTokenSet: !!this.props.token,
                token: this.props.token,
                stepsLength: this.props.steps.length,
            };
            const data = JSON.stringify({
                props: selectProps,
                state: this.state,
            });
            return <InvalidToken data={data} token={query.parse().token} />;
        }
    }
}

export function getStepProblem(step) {
    const isProblemStep = step && step.component.props.problem;
    if (isProblemStep) {
        return step.component.props.problem;
    }
}

export function mapStateToProps(store) {
    const {
        consultationError,
        id,
        practice,
        token,
        tokenChecked,
    } = store.data.consultations.consultation;
    const {steps, reloading, progress} = store.form;
    const undoStepReverseIndex = steps
        .slice(0)
        .reverse()
        .findIndex((step) => step.component.completed && step.undoable);
    const undoStepIndex =
        undoStepReverseIndex === -1
            ? undoStepReverseIndex
            : steps.length - 1 - undoStepReverseIndex;
    const activeStep = steps.find(
        (step) => !step.component.completed && !step.loading,
    );
    const undoableSteps = steps.filter((step) => step.undoable);
    const isStepLoading = steps.some((step) => step.loading);

    return {
        activeProblem: getStepProblem(activeStep),
        activeStep,
        loggedIn: store.accounts.user.loggedIn,
        steps,
        consultationError,
        consultationPracticeName: practice.name,
        consultationId: id,
        isModalOpen: !!store.app.modal.type,
        isStepLoading,
        patients: store.accounts.user.patients.items,
        progress,
        reloading,
        token,
        tokenChecked,
        undoableSteps,
        undoStepIndex,
        userId: store.accounts.user.id,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        checkConsultationToken: (token) =>
            dispatch(checkConsultationToken(token)),
        hideModal: () => dispatch(hideModal()),
        logoutUser: () => dispatch(logoutUser()),
        setStepToUndo: (stepId) => dispatch(setStepToUndo(stepId)),
        showNotification: (notification) =>
            dispatch(showNotification(notification)),
    };
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Consultation),
);
